@font-face {
    font-family: 'ChristmasSquad';
    src: url('../public/ChristmasSquad.otf');
}

*, *::before, *::after {
    padding: 0;
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  body {
    background-color: rgb(232, 65, 65);
    background-image: radial-gradient(rgba(232, 65, 65, 0.9333333333), rgba(10, 10, 10, 0.959) 30%), url('../public/sky.png');
    background-size: 50%;
    background-position-y: center;
    background-repeat-y: no-repeat;
    color: #fff;
    min-height: 100vh;
    display: grid;
    place-items: center;
    overflow: hidden;
  }

  a,a:visited,a:hover,a:active{
    color:#e84142;
    text-decoration: none;
    font-weight: 500;
  }
  
  $speed: 9s;
  $speed2: 4s;
  $rAngle: 50deg;

  .overlay{
    position: absolute;
    top:0; left:0;
    width: 100vw;
    height: 100vh;
    z-index: 2;

    .menu{
        position: relative;
        top:0;
        right:0;
        text-align: left;
        padding: 10px;
        font-size: 2em;

        .entries{
            float: left;
        }

        .connect-button{
            float: right;
        }

    }

    .heading{
        font-family: 'ChristmasSquad';
        text-align: center;

        h1{
            font-weight: normal;
            font-size: 10em;
        }

        h3{
            font-weight: normal;
            font-size: 6em;
        }
    }

    .panel{
        width: 50%;
        max-width: 620px;
        padding: 32px;
        background:rgba(0, 0, 0, 0.4);
        h2{
            margin-bottom: 1em;
        }
        ul{
            font-style: italic;
        }
        .controls{
            text-align:center;

            button{
                padding: 10px;
                font-size: 1.5em;
                border: 2px solid white;
                background: black;
                color: white;
                cursor: pointer;
            }
            button:hover,button:active,button:focus{
                background: rgba(255, 255, 255, 0.85);
                color:black;
            }
        }
    }

    .footer{
        position: absolute;
        width: 100%;
        bottom: 0;
        padding: 0 8px 8px 8px;
        background:rgba(0, 0, 0, 0.3);
        
        .credits{
          float:left;

          img{
            vertical-align: middle;
            width: 1em;
          }
        }

        .tweet{
          float: right;
          
          img{
            width: 1.25em;
            height: auto;
            display: inline-block;
            vertical-align: middle;
          }

          span{
            display: none;
            color: #fff;
          }

          &:hover span{
            display: inline-block;
          }

        }
    }
  
  }

  .scene{
    *:not(:empty), * {
        transform-style: preserve-3d;
      }
  }

  .ReactModalPortal{
    z-index: 4;
    text-align: center;

    .modal-close-btn{
        position: absolute;
        top: 4px;
        right: 4px;
        font-size: 1.5em;
        width: 1.5em;
        height: 1.5em;
        background: none;
        border: none;
        cursor: pointer;
    }

    .user-nfts{
        max-height: 60vh;
        overflow-y: scroll;
    }

    .user-nft{
        cursor: pointer;
        display: inline-block;
        box-sizing: content-box;
        margin-right: 16px;
        max-width: 160px;
        vertical-align: top;
        margin-bottom: 36px;

        &.selected{
            border: 3px solid #e84142;
        }
        .nft-image{
            width: 160px;
            height: 160px;

            img{
                max-width: 100%;
            }
        }
    }

  }

  #action-bar{
    background-color: #000;
    color: #fff;
    padding: 32px;
    
    .wrapper{
        display: flex;
        align-items: center;
    }
    .deposit-button{
        cursor: pointer;
        background: #e84142;
        padding: 12px;
    }
  }

  .outer {
    position: absolute;
    top: 50%; left: 50%;
    perspective: 800px;
  
    &_scene {
      position: absolute;
      top: 50%; left: 50%;
      animation: sceneRotate $speed infinite ease-in-out alternate;
    }
  
    &_floor {
      position: absolute;
      top: 50%; left: 50%;
      width: 3200px; height: 3200px;
      background-image: url('../public/black_floor.jpg');
      background-repeat: repeat;
      transform: translate(-50%, -50%) rotateX(90deg) translateZ(-240px);

    }
  }
  
  .gift {
    position: absolute;
    top: 240px;
    transform: rotateY(var(--ry, 0)) translateZ(525px) rotateY(calc(var(--ry, 0) * -1));
  
    $count: 8;
    @for $i from 0 to $count {
      &:nth-child(#{$i + 1}) {
        --ry: #{360deg / $count * $i + 112.5deg};
        --delay: #{$speed2 / $count * $i * - 3};
      }
    }
    &_body {
        position: absolute;
        left: -60px;
        width: 120px; height: 120px;
        transform-origin: bottom;
        animation:
          giftBottom $speed2 var(--delay, 0s) infinite,
          giftRotate $speed2 var(--delay, 0s) infinite;
    
        @keyframes giftBottom {
          0%, 55%, 94%, 100% { bottom: 0px; animation-timing-function: ease-out; }
          75% { bottom: 420px; animation-timing-function: ease-in; }
        }
    
        @keyframes giftRotate {
          0%, 50% { transform: scaleY(1) rotateY(0deg); animation-timing-function: ease-out; }
          55%     { transform: scaleY(0.8) rotateY(0deg); animation-timing-function: ease-in; }
          75%     { transform: scaleY(1) rotateY(240deg); animation-timing-function: ease-out; }
          94%     { transform: scaleY(1.2) rotateY(360deg); }
          97%     { transform: scaleY(0.8) rotateY(360deg); }
          100%    { transform: scaleY(1) rotateY(360deg); }
        }
    
        & > div {
          position: absolute;
          inset: 0;
          background-color: rgb(218, 31, 31);
          box-shadow:
            0 0 20px rgb(218, 31, 31) inset,
            0 0 50px hsl(12, 91%, 66%) inset;
    
          &:nth-child(1) { transform: translateZ(60px); }
          &:nth-child(2) { transform: rotateY(90deg) translateZ(60px); }
          &:nth-child(3) { transform: rotateY(180deg) translateZ(60px); }
          &:nth-child(4) { transform: rotateY(270deg) translateZ(60px); }
          &:nth-child(5) { transform: rotateX(90deg) translateZ(60px); }
          &:nth-child(6) { transform: rotateX(270deg) translateZ(60px); }
        }

        .gift_side{
            background-image: url('../public/gift_side.png');
            background-size: cover;
        }

        .gift_top{
            background-image: url('../public/gift_top.png');
            background-size: cover;
        }
      }
    
      &_shadow {
        position: absolute;
        left: -70px; top: -70px;
        width: 140px; height: 140px;
        background-color: #0005;
        transform: rotateX(90deg) translateZ(1px);
        filter: blur(20px);
        animation: giftShadow $speed2 var(--delay, 0s) infinite;
    
        @keyframes giftShadow {
          0%, 50% { transform: rotateX(90deg) translateZ(1px) scaleY(1) rotateZ(0deg); opacity: 1; animation-timing-function: ease-out; }
          55%     { transform: rotateX(90deg) translateZ(1px) scaleY(0.8) rotateZ(0deg); opacity: 1; animation-timing-function: ease-in; }
          75%     { transform: rotateX(90deg) translateZ(1px) scaleY(3) rotateZ(-240deg); opacity: 0.4; animation-timing-function: ease-out; }
          94%     { transform: rotateX(90deg) translateZ(1px) scaleY(1.2) rotateZ(-360deg); opacity: 1; }
          97%     { transform: rotateX(90deg) translateZ(1px) scaleY(0.8) rotateZ(-360deg); opacity: 1; }
          100%    { transform: rotateX(90deg) translateZ(1px) scaleY(1) rotateZ(-360deg); opacity: 1; }
        }
      }
  }
  
  .portal {
    position: absolute;
    top: 50%; left: 50%;
    width: 420px; height: 420px;
    background-image: linear-gradient(#201, #000 50%);
    border-radius: 50%;
    box-shadow: 0 0 20px #f77, 0 0 50px #7ff;
    perspective: 660px;
    overflow: hidden;
    animation: portalRotate $speed infinite ease-in-out alternate;
  
    @keyframes portalRotate {
      from { transform: translate(-50%, -50%) rotateY($rAngle); }
      to { transform: translate(-50%, -50%) rotateY($rAngle * -1); }
    }
  
    &_scene {
      position: absolute;
      top: 50%; left: 50%;
      animation: sceneRotate $speed infinite ease-in-out alternate;
  
      @keyframes sceneRotate {
        from { transform: rotateY($rAngle * -1); }
        to { transform: rotateY($rAngle); }
      }    
    }
  
    &_floor {
      position: absolute;
      top: 50%; left: 50%;
      width: 3200px; height: 3200px;
      transform: translate(-50%, -50%) rotateX(90deg) translateZ(-240px);
      background-color: #444;
      background-image:
        radial-gradient(#111a, #1110, #000 60%),
        repeating-linear-gradient(#fff5 0 5px, #fff0 0 75px),
        repeating-linear-gradient(90deg, #fff5 0 5px, #fff0 0 75px);    
    }
  }
  
  .cube {
    position: absolute;
    top: 240px;
    transform: rotateY(var(--ry, 0)) translateZ(525px) rotateY(calc(var(--ry, 0) * -1));
  
    $count: 4;
    @for $i from 0 to $count {
      &:nth-child(#{$i + 2}) {
        --ry: #{180deg / $count * $i + 112.5deg};
        --delay: #{$speed2 / $count * $i * -1.5};
      }
    }
  
    &_body {
      position: absolute;
      left: -60px;
      width: 120px; height: 120px;
      transform-origin: bottom;
      animation:
        cubeBottom $speed2 var(--delay, 0s) infinite,
        cubeRotate $speed2 var(--delay, 0s) infinite;
  
      @keyframes cubeBottom {
        0%, 55%, 94%, 100% { bottom: 0px; animation-timing-function: ease-out; }
        75% { bottom: 420px; animation-timing-function: ease-in; }
      }
  
      @keyframes cubeRotate {
        0%, 50% { transform: scaleY(1) rotateY(0deg); animation-timing-function: ease-out; }
        55%     { transform: scaleY(0.8) rotateY(0deg); animation-timing-function: ease-in; }
        75%     { transform: scaleY(1) rotateY(240deg); animation-timing-function: ease-out; }
        94%     { transform: scaleY(1.2) rotateY(360deg); }
        97%     { transform: scaleY(0.8) rotateY(360deg); }
        100%    { transform: scaleY(1) rotateY(360deg); }
      }
  
      & > div {
        position: absolute;
        inset: 0;
        background-color: #e84142;
        box-shadow:
          0 0 16px #f75f5f inset,
          0 0 36px #fa8c61 inset;
  
        &:nth-child(1) { transform: translateZ(60px); }
        &:nth-child(2) { transform: rotateY(90deg) translateZ(60px); }
        &:nth-child(3) { transform: rotateY(180deg) translateZ(60px); }
        &:nth-child(4) { transform: rotateY(270deg) translateZ(60px); }
        &:nth-child(5) { transform: rotateX(90deg) translateZ(60px); }
        &:nth-child(6) { transform: rotateX(270deg) translateZ(60px); }
      }
    }
  
    &_shadow {
      position: absolute;
      left: -70px; top: -70px;
      width: 140px; height: 140px;
      background-color: #0005;
      transform: rotateX(90deg) translateZ(1px);
      filter: blur(20px);
      animation: cubeShadow $speed2 var(--delay, 0s) infinite;
  
      @keyframes cubeShadow {
        0%, 50% { transform: rotateX(90deg) translateZ(1px) scaleY(1) rotateZ(0deg); opacity: 1; animation-timing-function: ease-out; }
        55%     { transform: rotateX(90deg) translateZ(1px) scaleY(0.8) rotateZ(0deg); opacity: 1; animation-timing-function: ease-in; }
        75%     { transform: rotateX(90deg) translateZ(1px) scaleY(3) rotateZ(-240deg); opacity: 0.4; animation-timing-function: ease-out; }
        94%     { transform: rotateX(90deg) translateZ(1px) scaleY(1.2) rotateZ(-360deg); opacity: 1; }
        97%     { transform: rotateX(90deg) translateZ(1px) scaleY(0.8) rotateZ(-360deg); opacity: 1; }
        100%    { transform: rotateX(90deg) translateZ(1px) scaleY(1) rotateZ(-360deg); opacity: 1; }
      }
    }
  }